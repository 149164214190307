import React, {useCallback, useMemo} from "react";
import MapFiltersSection from "./MapFiltersSection";
import './map-filters.scss';
import {useTranslation} from "react-i18next";
import SliderField from "../../../../components/form/SliderField/SliderField";
import * as CheckboxField from "../../../../components/form/CheckboxField/CheckboxField";
import {Form} from "../../../../components/form/Form/Form";
import {getFilledFieldsObjOnFormSubmit} from "../../../../utils/utils";
import {LoadingComponent} from "../../../../components/LoadingComponent/LoadingComponent";
import {useMapFiltersContext} from "../../../../context/mapFiltersContext";


const MapRoadsFilter = () => {
    const {loading, enabledFilters, filters, setMapFilters} = useMapFiltersContext();
    const {t} = useTranslation();

    const lanesDefaultValue = useMemo(() => {
        return filters.lanesNumMin && filters.lanesNumMax
            ? [filters.lanesNumMin, filters.lanesNumMax]
            : [1, 10]
    }, [filters]);

    const onFormSubmit = useCallback(async (event) => {
        const formDataObj = getFilledFieldsObjOnFormSubmit(event);
        setMapFilters(formDataObj);
    }, [setMapFilters])

    return (
        <div className="map-filters">
            <LoadingComponent isLoading={loading}>
                <Form
                    name="mapFiltersForm"
                    onFormSubmit={onFormSubmit}
                    submitBtnTitle={t('map-filters.apply-filters-btn')}
                    testId="mapFiltersForm"
                    submitBtnTestId="mapFiltersFormSubmitBtn"
                >
                    <MapFiltersSection title={t('map-filters.transport-modes-group')}>
                        <div className="map-filters__modes-section">
                            {
                                enabledFilters.modes.map((name, idx) => {
                                    if (name) {
                                        return <CheckboxField.Labeled
                                            key={idx}
                                            inline={true}
                                            name={name}
                                            value={`${filters.modes[name]}`}
                                            alignCheckboxRight
                                        />;
                                    }
                                })
                            }
                        </div>
                    </MapFiltersSection>
                    <MapFiltersSection title={t('map-filters.lanes-number-group')}>
                        <SliderField
                            name="lanes"
                            min={1}
                            max={11}
                            step={1}
                            defaultValue={lanesDefaultValue}
                            floatLabels={false}
                            required
                            inline
                        />
                    </MapFiltersSection>
                    <MapFiltersSection title={t('map-filters.highway-type-group')}>
                        <div className="map-filters__modes-section">
                            {
                                enabledFilters.highwayTypes.map((name, idx) => {
                                    return <CheckboxField.Labeled
                                        key={idx}
                                        inline={true}
                                        name={name}
                                        value={`${filters.highwayTypes[name]}`}
                                        alignCheckboxRight
                                    />;
                                })
                            }
                        </div>
                    </MapFiltersSection>
                </Form>
            </LoadingComponent>
        </div>
    )
}

export default MapRoadsFilter;