import React, {
  ChangeEventHandler,
  FormEvent,
  forwardRef,
  ForwardRefExoticComponent,
  InputHTMLAttributes,
  PropsWithChildren,
  RefAttributes,
  useCallback
} from "react";
import "./form.scss";
import Button from "../../buttons/Button";


type FormProps = {
  name: string;
  onFormSubmit?: (event: any) => void;
  onChange?: React.FormEventHandler<HTMLFormElement>;
  submitBtnTitle?: string;
  disabled?: boolean;
  submitBtnTestId?: string;
  testId?: string;
} & React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>;

export type FormFieldProps = {
  label?: string;
  alertLabel?: string;
  required?: boolean;
  hidden?: boolean;
  name: string;
  inline?: boolean;
  fieldGroupClassName?: string;
  value?: any;
  visible?: boolean;
  onChange?: React.ChangeEventHandler<any> | undefined;
  testId?: string;
};

const Row = React.forwardRef((props: React.PropsWithChildren<any>, ref: React.ForwardedRef<HTMLDivElement>) => {
  return <div className="form-edit-row" ref={ref}>{props.children}</div>;
});

const Form: ForwardRefExoticComponent<PropsWithChildren<FormProps> & RefAttributes<HTMLFormElement>> = forwardRef((
  props,
  ref?
) => {
  const {name, onChange, testId, onFormSubmit, ...rest} = props;


  const onSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onFormSubmit?.(event);
  }, [onFormSubmit])

  return (
    <form
      name={name}
      className="form-edit"
      onSubmit={onSubmit}
      onChange={onChange}
      ref={ref}
      {...(testId ? {'data-testid': testId} : {})}
      {...rest}
      // onSubmitCapture={(e) => console.log("onSubmitCapture", e)}
      // onInvalid={(e) => console.log("onInvalid", e)}
      // onInvalidCapture={(e) => console.log("onInvalidCapture", e)}
    >
      <div className="form-edit_body">
        {props.children}
      </div>
      <div>
        <Button
        type="submit"
        text={props.submitBtnTitle || "Submit"}
        disabled={props?.disabled}
        colorType="dark"
        {...(props.submitBtnTestId ? {'testId': props.submitBtnTestId} : {})}
      />
      </div>
    </form>
  );
});

export {Row, Form};
