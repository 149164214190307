import React, {useCallback, useEffect} from "react";
import {useEntityLoader, UseEntityLoaderProps} from "../../hooks/entites/useEntityLoader";
import {LoadingComponent} from "../LoadingComponent/LoadingComponent";
import EntityForm from "./EntityForm";
import {isErrorResponse} from "../../utils/utils";
import {useToastContext} from "../../context/toastContext";
import {useTranslation} from "react-i18next";
import {BaseEntity, BaseEntityNameLess} from "../../api/entities/BaseEntity";
import {setEntity} from "../../redux/entity/entity-reducer";
import {useDispatch} from "react-redux";
import UndoLastOperationButton
    from "../../project-modes/RoadNetworkMode/RoadNetworkManager/UndoLastOperationButton/UndoLastOperationButton";
import {setSourceReloadRequired} from "../../redux/map/map-reducer";
import {flyToFeatureAndHighlight} from "../../utils/mapUtils";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectMapLayerIdByEntityName} from "../../redux/selectors/selectors";
import {useLocation, useNavigate} from "react-router-dom";
import {useMapFeatureLoader} from "../../hooks/entites/useMapFeatureLoader";


//TODO loadByDefault is to avoid entity load in TransitRouteEdit => update architecture
type Props = {
    entityId: string;
    entityName: string;
    layerId: string;
    getUrlOnSaveFn?: (resp: any) => string | undefined;
    getUrlOnDeleteFn?: (resp: any) => string | undefined;
    actionButtons?: JSX.Element | JSX.Element[];
    undoLastActionBtn?: boolean;
    loadByDefault?: boolean;
} & UseEntityLoaderProps

const EntityEditor = <T extends BaseEntityNameLess>({
                                                        entityId,
                                                        entityName,
                                                        layerId,
                                                        saveFnArgs,
                                                        presetProperty,
                                                        getUrlOnSaveFn,
                                                        getUrlOnDeleteFn,
                                                        actionButtons,
                                                        undoLastActionBtn = false,
                                                        entityView,
                                                        loadByDefault = true
                                                    }: Props) => {
    const {
        loading,
        entity,
        metadata,
        loadData,
        saveFn,
        deleteFn
    } = useEntityLoader<T>({entityName, saveFnArgs, presetProperty});
    const entityLayerId: string = useTypedSelector((state) => selectMapLayerIdByEntityName(state, entityName));
    const {loadFeature} = useMapFeatureLoader({entityName});
    const {addToast} = useToastContext();
    const {t} = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const updatedEntityId = entityId === 'new' ? null : entityId;

    useEffect(() => {
        if (loadByDefault) {
            (async function () {
                //TODO redirect to list or project if entity doesn't exist
                await loadData({entityName, entityId: updatedEntityId, entityView});

                if (updatedEntityId) {
                    const resp = await loadFeature({entityId: updatedEntityId, entityName, findFeatureParent: true});
                    if (resp.feature) {
                        await flyToFeatureAndHighlight({
                            entityId: updatedEntityId,
                            entityName,
                            layerId: entityLayerId,
                            feature: resp.feature,
                            dispatch
                        });
                    }
                }
            })();
        }
    }, [entityName, updatedEntityId, entityLayerId, entityView, loadByDefault]);

    const changeFormHandler = useCallback((formDataObj: { [key: string]: string | number }) => {
        dispatch(setEntity({entityName, entity: {...entity, ...formDataObj}}));
    }, [entity])

    const saveEntity = useCallback(async (formDataObj: { [key: string]: string | number }) => {
        const abortController = new AbortController();

        const resp = await saveFn({
            id: updatedEntityId,
            entityName,
            data: formDataObj,
            abortSignal: abortController.signal
        });

        if (isErrorResponse(resp)) {
            addToast(`${resp.error_description}`, 'error');
        } else {
            addToast(t('common.save-successful'), 'success');
            await loadData({entityName, entityId: updatedEntityId, entityView});
            if (layerId) {
                dispatch(setSourceReloadRequired({sourceId: layerId, isReloadRequired: true}));
            }

            if (getUrlOnSaveFn) {
                const url = getUrlOnSaveFn?.(resp);
                if (url) {
                    navigate(url, {state: {prevUrl: location.pathname}, relative: "path"});
                }
            }
        }

    }, [entityName, updatedEntityId, layerId]);

    const deleteEntity = useCallback(async ({entity}: {entity: T}) => {
        const resp = await deleteFn?.({entity});

        if (isErrorResponse(resp)) {
            addToast(`${resp.error_description}`, 'error');
        } else {
            addToast(t('common.delete-successful'), 'success');

            if (layerId) {
                dispatch(setSourceReloadRequired({sourceId: layerId, isReloadRequired: true}));
            }

            if (getUrlOnDeleteFn) {
                const url = getUrlOnDeleteFn?.(resp);
                if (url) {
                    navigate(url, {state: location.pathname, relative: "path"});
                }
            }
        }
    }, [layerId])

    const onLastActionUndo = useCallback(async () => {
        await loadData({entityName, entityId: updatedEntityId});
    }, [loadData, updatedEntityId, entityName]);

    return (
        <LoadingComponent isLoading={loading || !Object.values(entity).length}>
            <EntityForm
                entity={entity}
                metadata={metadata}
                changeFormFn={changeFormHandler}
                saveFn={saveEntity as any}
                deleteFn={deleteEntity as any}
                icons={
                    <>
                        {actionButtons}
                        {
                            undoLastActionBtn
                                ? <UndoLastOperationButton onFinishFn={onLastActionUndo}/>
                                : null
                        }
                    </>
                }
            />
        </LoadingComponent>
    )
}

export default EntityEditor;