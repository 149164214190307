import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {DataById, MapFiltersType} from "../redux/map/types";
import {roadManagementApi} from "../api/roadManagementApi";
import {isErrorResponse} from "../utils/utils";
import {FormDataObject} from "../components/Entities/EntityForm";
import {usePresetsContext} from "./presetsContext";


type TemporaryMapFilterType = {
    modes: DataById<boolean>;
    lanesNumMin: number | null;
    lanesNumMax: number | null;
    highwayTypes: DataById<boolean>;
}

const DEFAULT_ENABLED_MAP_FILTERS: MapFiltersType = {
    modes: [],
    lanesNumMin: 1,
    lanesNumMax: 10,
    highwayTypes: []
};

const DEFAULT_MAP_FILTERS: TemporaryMapFilterType = {
    modes: {},
    lanesNumMin: 1,
    lanesNumMax: 10,
    highwayTypes: {}
};

const MapFiltersContext = createContext({
    loading: false,
    enabledFilters: DEFAULT_ENABLED_MAP_FILTERS,
    filters: DEFAULT_MAP_FILTERS,
    mapFilter: [] as any,
    setMapFilters: (formDataObj: FormDataObject) => {
        return;
    }
});

const useMapFiltersContext = () => {
    return useContext(MapFiltersContext);
};

const MapFiltersProvider = ({children}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const {selectedPresetId} = usePresetsContext();
    const [enabledFilters, setEnabledFilters] = useState<MapFiltersType>(DEFAULT_ENABLED_MAP_FILTERS);
    const [filters, setFilters] = useState<TemporaryMapFilterType>(DEFAULT_MAP_FILTERS);

    const mapFilter = useMemo(() => {
        const {modes, lanesNumMin, lanesNumMax, highwayTypes} = filters;

        const _filter: any[] = ["all"];
        if (enabledFilters.modes.length) {
            const modesFilters = (['any'] as any).concat(enabledFilters.modes
                .filter(mode => modes[mode])
                .map(mode => ['in', mode, ['get', 'modes']]));
            _filter.push(modesFilters);
        }
        if (lanesNumMin) {
            _filter.push([">=", ['get', 'lanes'], lanesNumMin]);
        }
        if (lanesNumMax) {
            _filter.push(["<=", ['get', 'lanes'], lanesNumMax]);
        }
        if (highwayTypes.length) {
            _filter.push(["in", ['get', 'highway'], ["literal", highwayTypes]]);
        }

        return _filter.length > 1 ? _filter : null;
    }, [enabledFilters, filters])

    useEffect(() => {
        if (selectedPresetId) {
            const abortController = new AbortController();
            (async function () {
                setLoading(true);

                const enabledFilters = {} as MapFiltersType;
                const filters = {} as TemporaryMapFilterType;

                const resp = await roadManagementApi.getModes({presetId: selectedPresetId}, abortController.signal);
                if (resp && Array.isArray(resp)) {
                    // if (!isErrorResponse(resp)) {
                    enabledFilters['modes'] = resp;
                    filters['modes'] = resp.reduce((acc, mode) => {
                        acc[mode] = true;
                        return acc;
                    }, {});
                } else {
                    console.error('Replan. MapFilters. listAllModes response doesn\'t include array of modes');
                }

                enabledFilters['highwayTypes'] = [];
                const highwaysResp = await roadManagementApi.listAllHighways({presetId: selectedPresetId}, abortController.signal);
                if (!isErrorResponse(highwaysResp) && Array.isArray(highwaysResp)) {
                    filters['highwayTypes'] = highwaysResp.reduce((acc, item) => {
                        acc[item.name] = true;
                        enabledFilters['highwayTypes'].push(item.name);
                        return acc;
                    }, {});
                } else {
                    console.error('Replan. MapFilters. listAllHighways response doesn\'t include array of highways');
                }

                setEnabledFilters(enabledFilters);
                setFilters(filters);

                setLoading(false);
            }());
        }
    }, [selectedPresetId]);

    const setMapFilters = useCallback((formDataObj: FormDataObject) => {
        const appliedModes = enabledFilters.modes.reduce((acc, name) => {
            acc[name] = formDataObj[name] === 'true';
            return acc;
        }, {});
        const [lanesNumMin, lanesNumMax] = JSON.parse(formDataObj['lanes'] as string);

        const appliedHighwayTypes = enabledFilters.highwayTypes.reduce((acc, name) => {
            acc[name] = formDataObj[name] === 'true';
            return acc;
        }, {});

        const filters = {
            modes: appliedModes,
            lanesNumMin,
            lanesNumMax,
            highwayTypes: appliedHighwayTypes,
        };

        setFilters(filters);
    }, [enabledFilters]);

    const value = {
        loading,
        enabledFilters,
        filters,
        mapFilter,
        setMapFilters
    }

    return (
        <MapFiltersContext.Provider value={value}>
            {children}
        </MapFiltersContext.Provider>
    );
};

export {MapFiltersProvider, useMapFiltersContext};